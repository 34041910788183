import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Next Pop Up Mobility classes with Glenn be today, March 10th,
at 12:00am. This is free for all members so pop in if you can make it!
 `}</em></strong></p>
    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Power Clean 5×1 to a 1RM`}</p>
    <p>{`Front Squat 5×1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Grelen”`}</strong></p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`10-Clean & Jerks (135/95)`}</p>
    <p>{`21-KBS (53/35)`}</p>
    <p>{`12-Pullups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you still need to be judged for Open WOD 19.3 we’ll be judging
after class today from 1:30-2:30.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      